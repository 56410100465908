<template>
	<div class="home">
		<div class="left" v-loading="loading1">
			<div class="tree">
				<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps" :default-expanded-keys="[1]"
					:default-checked-keys="[1]" :highlight-current="true" @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{ node, data }" style="width: 100%;height: 100%;">
						<span style="width: 100%;height: 100%;line-height: 32px;">{{ data.grid_name }}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">
			<div class="transformer">
				<div class="clearfix electric-select">
					<el-form :inline="true">
						<el-form-item label="网格名：">
							<el-input class="shuru" v-model="searchName"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="chaxun()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
							<el-button v-if="newbutton" type="primary" @click="dialogTableVisible = true">
								<i class="el-icon-plus"></i>
								新增
							</el-button>
						</el-form-item>
					</el-form>

				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" stripe style="width: 100%;margin-top: 20px;">
						<el-table-column prop="name" label="社区" height show-overflow-tooltip align="center"></el-table-column>
						<el-table-column prop="grid_name" label="网格名" width="300" height show-overflow-tooltip align="center"></el-table-column>
						<el-table-column label="地图划分" height show-overflow-tooltip align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.gridxy">是</span>
								<span v-else>/</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="300" align="center">
							<template slot-scope="scope">
								<el-button @click="mapdivision(scope.row)" type="infor">地图划分</el-button>
								<el-button @click="xiugai(scope.row)" type="primary">修改</el-button>
								<el-button @click="shanchu(scope.row)" type="warning">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum"></el-pagination>
					</div>
				</div>
				<el-dialog title="新增网格" width="30%" :visible.sync="dialogTableVisible" :before-close="handleClose"
					:close-on-click-modal="false">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="网格名称">
							<el-input v-model="form.name"></el-input>
						</el-form-item>
						<el-form-item label="网格属性">
							<el-input v-model="form.status"></el-input>
						</el-form-item>
						<el-form-item label="网格备注">
							<el-input v-model="form.remark"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit">立即创建</el-button>
							<el-button @click="close">取消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
				<el-dialog title="修改网格" width="30%" :visible.sync="xiugaiVisible" :before-close="handleClose2"
					:close-on-click-modal="false">
					<el-form ref="form" :model="form2" label-width="80px">
						<el-form-item label="网格名称">
							<el-input v-model="form2.name"></el-input>
						</el-form-item>
						<el-form-item label="网格属性">
							<el-input v-model="form2.type"></el-input>
						</el-form-item>
						<el-form-item label="网格备注">
							<el-input v-model="form2.remark"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit2">修改</el-button>
							<el-button @click="close2">取消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>

				<el-dialog title="地图划分" width="540px" :visible.sync="mapVisible" :before-close="handleClose3"
					:close-on-click-modal="false">
					<el-form ref="form" :model="form3" label-width="100px" class="form3style">
						<el-form-item>
							<div class="spanstyle" @click="openNetMap">绘制网格和中心坐标</div>
						</el-form-item>
						<el-form-item label="网格坐标">
							<el-input v-model="form3.netLocation" readonly></el-input>
						</el-form-item>
						<el-form-item label="中心坐标">
							<el-input v-model="form3.centerLocation" readonly></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit3">确定</el-button>
							<el-button @click="close3">取消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>

				<el-dialog title="绘制网格和中心坐标" width="50%" :visible.sync="netMapVisible" :before-close="handleClose4"
					:close-on-click-modal="false">
					<el-button type="primary" id="addPolygon" style="margin-bottom: 10px;">{{ netMapText }}</el-button>
					<el-button type="primary" id="addPoint" style="margin-bottom: 10px;">{{ centerMapText }}</el-button>
					<el-button type="primary" id="changeMapType" style="margin-bottom: 10px;">切换模式</el-button>
					<el-button type="primary" @click="netMapVisible = false">确定</el-button>
					<div style="width:100%;height:440px">
						<el-amap vid="amapNetDemo" :zoom="netZoom" :events="netEvents" :center="netCenter"
							:amap-manager="amapManager">
							<el-amap-marker v-for="(marker, index) in markers" :key="index" :position="marker.position"
								:events="marker.events" :visible="marker.visible" :draggable="marker.draggable"
								:vid="index"></el-amap-marker>
							<el-amap-polygon v-for="(polygon, index) in polygons" :key="index" :vid="index"
								:ref="`polygon_${index}`" :path="polygon.path" fillColor="#2b83f9" fillOpacity="0.4"
								strokeWeight="3" strokeColor="#2b83f9" strokeOpacity="0.5"></el-amap-polygon>
						</el-amap>
					</div>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getwanggelist,
		addwangege,
		updatewangege,
		gridlist,
		deletewangege,
		updateGridMapInfo
	} from '@/api/wangge'
	import {
		AMapManager
	} from 'vue-amap';
	let amapManager = new AMapManager();
	export default {
		data() {
			return {
				time: new Date(),
				treeId: '',
				searchName: '',
				search: '',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 20, 30, 40],
					pagesize: 8,
					totalnum: 0
				},
				form: {
					name: '',
					status: '',
					remark: ''
				},
				defaultProps: {
					children: 'wanggelist',
					label: 'grid_name'
				},
				treeSelectId: '',
				tree: [],
				newbutton: false,
				levelcode: '',
				xiugaiVisible: false,
				form2: {
					level: '',
					name: '',
					type: '',
					remark: '',
					id: ''
				},
				xiugaiLevelcode: '',
				gridid: '',
				loading1: true,
				loading2: true,
				mapVisible: false,
				form3: {
					netLocation: '',
					centerLocation: ''
				},
				//网格坐标相关
				netMapText: '绘制网格',
				centerMapText: '标注中心',
				mapTypeFlag: false,
				amapManager,
				netMapVisible: false,
				netCenter: [118.627895, 32.059093],
				netZoom: 14,
				polygons: [],
				markers: [],
				netEvents: {
					init: map => {
						let that = this;
						let addPolygonButton = document.getElementById('addPolygon');
						let addPointButton = document.getElementById('addPoint');
						let changeMapTypeButton = document.getElementById('changeMapType');
						// 引入放大缩小工具插件
						map.addControl(
							new AMap.ToolBar({
								liteStyle: true
							})
						);
						// 引入绘图插件  全局调用绘图插件
						self.mouseTool = new AMap.MouseTool(map);

						//默认模式
						//that.mapTypeFlag = false;
						//map.setLayers([new AMap.TileLayer()]);


						//切换模式
						AMap.event.addDomListener(
							changeMapTypeButton,
							'click',
							() => {
								that.mapTypeFlag = !that.mapTypeFlag
								//默认模式和卫星模式切勿
								if (that.mapTypeFlag) {
									map.setLayers([new AMap.TileLayer.Satellite()]);
								} else {
									map.setLayers([new AMap.TileLayer()]);
								}
							},
							false
						);

						//绘制标注点
						AMap.event.addDomListener(
							addPointButton,
							'click',
							resData => {
								//先清除点
								//console.log(self.marker)
								if (self.marker) {
									self.marker.setMap(null);
									self.mouseTool.close(true);
								}
								if (that.markers.length > 0) {
									that.markers = [];
								}
								that.form3.centerLocation = '';
								self.mouseTool.marker();
							},
							false
						);
						// 绘制围栏
						AMap.event.addDomListener(
							addPolygonButton,
							'click',
							resData => {
								//先清除网格
								if (self.polygon) {
									self.polygon.setMap(null);
									self.mouseTool.close(true);
									self.flagBit = false;
									self.renderFenceOk = false;
								}
								if (that.polygons.length != 0) {
									that.polygons = [];
								}
								that.form3.netLocation = '';

								if (self.flagBit) {
									self.mouseTool.close(false);
								} else {
									self.mouseTool.polygon();
								}
							},
							false
						);
						//绘制完成触发此事件
						self.mouseTool.on('draw', resData => {
							//console.log(resData.obj)
							let picType = resData.obj.CLASS_NAME;
							if (picType == "AMap.Polygon") {
								self.mouseTool.close(false);
								self.flagBit = true;
								// 把坐标点传给连接坐标点插件
								//console.log('----Path-----')
								//console.log(resData.obj.getPath())
								let arr = resData.obj.getPath().map(item => {
									return [item.lng, item.lat];
								});
								//console.log(arr)
								self.fenceArr = arr;
								let newArr = [...arr];
								//console.log(newArr)
								let newArrStr = '';
								newArr.forEach(item => {
									newArrStr += item[0] + ',' + item[1] + ';';
								})
								//console.log(newArrStr)
								let strRes = newArrStr.substr(0, newArrStr.length - 1);
								that.form3.netLocation = strRes;
								//画多边形
								self.polygon = new AMap.Polygon({
									map: map,
									path: self.fenceArr,
									draggable: false,
									fillColor: "#2b83f9",
									fillOpacity: "0.4",
									strokeWeight: "3",
									strokeColor: "#2b83f9",
									strokeOpacity: "0.5",
								});
								that.polygons = [{
									path: [...self.fenceArr]
								}];
								self.renderFenceOk = true;
								that.$message({
									type: 'success',
									message: '网络坐标绘制完成'
								})
							}

							if (picType == "AMap.Marker") {
								self.mouseTool.close(false);
								//console.log('----position-----')
								//console.log(resData.obj.getPosition())
								let {
									lng,
									lat
								} = resData.obj.getPosition();
								self.marker = new AMap.Marker({
									position: [lng, lat],
									map: map
								})
								that.markers = [{
									position: [lng, lat]
								}];
								that.netCenter = [lng, lat];
								that.form3.centerLocation = lng + ',' + lat;
								that.$message({
									type: 'success',
									message: '中心坐标绘制完成'
								})
							}

						});
					}
				}

			};
		},
		watch: {
			'form3.netLocation'(val) {
				if (val) {
					this.netMapText = '重新绘制网格';
				} else {
					this.netMapText = '绘制网格';
					//this.netCenter = [118.627895, 32.059093];
					this.polygons = [];
				}
			},
			'form3.centerLocation'(val) {
				if (val) {
					this.centerMapText = '重新标注中心';
				} else {
					this.centerMapText = '标注中心';
					//this.netCenter = [118.627895, 32.059093];
					this.markers = [];
				}
			}
		},
		mounted() {
			this.getTree();
			this.getList();
		},
		methods: {
			// nodeClick() {
			// 	let node = this.$refs.tree.getCurrentNode()
			// 	this.$emit("treeClick", node.name, node.id)
			// },
			getTree() {
				this.loading1 = true;
				getwanggelist({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						this.tree = res.detail.levelCode;
						// console.log(this.tree);
						for (var a = 0; a < this.tree.length; a++) {
							this.tree[a].grid_name = this.tree[a].name;
							this.tree[a].ji = 1;
							this.tree[a].num = a;
							for (var i = 0; i < this.tree[a].wanggelist.length; i++) {
								this.tree[a].wanggelist[i].name = this.tree[a].name.split('(')[0];
								this.tree[a].wanggelist[i].ji = 2;
								this.tree[a].wanggelist[i].num = i;
								this.tree[a].wanggelist[i].prevnum = a;
							}
						}
					
						// this.tableData = this.tree[0].wanggelist;
						// this.$nextTick(() => {
						// 	this.$refs.tree.setCurrentKey(3);
						// })
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleClose: function(done) {
				var that = this;
				that.form.name = '';
				that.form.status = '';
				that.form.remark = '';
				done();
			},
			handleClose2: function(done) {
				var that = this;
				console.log(that.form3);
				that.form2 = that.form3;
				done();
			},
			//新增
			onSubmit() {
				var that = this;
				if (that.form.name == '') {
					that.$message.error('请填写网格名称');
					return;
				}
				if (that.form.status == '') {
					that.$message.error('请填写网格属性');
					return;
				}
				addwangege({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					regionlevel: that.levelcode,
					gridname: that.form.name,
					gridstatus: that.form.status,
					remark: that.form.remark
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							message: '添加成功',
							type: 'success'
						});
						that.form = {
							name: '',
							status: '',
							remark: ''
						};
						that.currentPage = 1;
						that.getTree();
						that.getList();
						that.dialogTableVisible = false;
					} else {
						that.$message({
							showClose: true,
							type: 'error',
							message: res.description
						});
					}
				})
			},
			//修改
			onSubmit2() {
				var that = this;
				if (that.form2.name == '') {
					that.$message.error('请填写网格名称');
					return;
				}
				if (that.form2.type == '') {
					that.$message.error('请填写网格属性');
					return;
				}
				updatewangege({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					gridid: that.form2.id,
					regionlevel: that.form2.level,
					gridname: that.form2.name,
					gridstatus: that.form2.type,
					remark: that.form2.remark
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							message: '修改成功',
							type: 'success'
						});
						that.form2 = {
							level: '',
							name: '',
							type: '',
							remark: '',
							id: ''
						};
						that.searchName = '';
						that.search = '';
						that.getList();
						that.getTree();
						that.xiugaiVisible = false;
					} else {
						that.$message({
							showClose: true,
							type: 'error',
							message: res.description
						});
					}
				})
			},
			close() {
				var that = this;
				that.dialogTableVisible = false;
				that.form = {
					name: '',
					status: '',
					remark: ''
				};
			},
			close2() {
				var that = this;
				that.xiugaiVisible = false;
				that.form2 = {
					level: '',
					name: '',
					type: '',
					remark: '',
					id: ''
				};
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			nodeClick(data) {
				// console.log(data);
				var that = this;
				that.searchName = '';
				that.search = '';
				that.tableData = [];
				that.levelcode = '';
				that.gridid = '';
				that.currentPage = 1;
				if (data.ji == 1) {
					that.newbutton = true;
					that.levelcode = data.levelcode;
				} else {
					that.newbutton = false;
					that.levelcode = data.grid_level;
					that.gridid = data.id;
				}
				that.getList();
			},
			chaxun() {
				this.currentPage = 1;
				this.search = this.searchName;
				this.getList();
			},
			getList() {
				var that = this;
				that.loading2 = true;
				gridlist({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					regionlevel: that.levelcode,
					gridid: that.gridid,
					page: that.currentPage,
					pagesize: that.fenye.pagesize,
					seachname: that.search
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.list;
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
			xiugai(a) {
				var that = this;
				console.log(a);
				that.form2 = {
					level: a.grid_level,
					name: a.grid_name,
					type: a.grid_type,
					remark: a.grid_remark,
					id: a.id
				};
				that.xiugaiVisible = true;
			},
			shanchu(a) {
				var that = this;
				that
					.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						deletewangege({
							'x-access-token': that.GLOBAL.token(),
							userid: that.GLOBAL.adminId(),
							regionlevel: a.grid_level,
							gridid: a.id
						}).then((res) => {
							if (res.result == '200') {
								that.$message({
									type: 'success',
									message: '删除成功!'
								});
								that.currentPage = 1;
								that.getList();
								that.getTree();
							} else {
								that.$message({
									showClose: true,
									type: 'error',
									message: res.description
								});
							}
						})
					})
					.catch(() => {});
			},
			//地图划分
			mapdivision(row) {
				//console.log(row)
				this.mapVisible = true;
				this.gridid = row.id;
				this.polygons = [];
				this.markers = [];
				this.form3.netLocation = row.gridxy;
				this.form3.centerLocation = row.gridcenterxy;
			},
			handleClose3(done) {
				this.gridid = '';
				//console.log(this.gridid)
				done();
			},
			handleClose4(done) {
				done();
			},
			close3() {
				var that = this;
				that.gridid = '';
				that.mapVisible = false;
				that.form3 = {
					netLocation: '',
					centerLocation: ''
				};
			},
			//地图划分确认
			onSubmit3() {
				var that = this;
				if (!that.form3.netLocation) {
					that.$message.error('请选择网格坐标，点击上方绘制网格和中心坐标即可');
					return;
				}
				if (!that.form3.centerLocation) {
					that.$message.error('请选择中心坐标，点击上方绘制网格和中心坐标即可');
					return;
				}
				updateGridMapInfo({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					gridid: that.gridid,
					gridXY: that.form3.netLocation,
					gridCenterXY: that.form3.centerLocation
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							message: '提交成功',
							type: 'success'
						});
						that.gridid = '';
						that.getList();
						that.mapVisible = false;
					} else {
						that.$message({
							showClose: true,
							type: 'error',
							message: res.description
						});
					}
				})
			},
			openNetMap() {
				this.netMapVisible = true;
				this.netZoom = 14;
				if (self.polygon) {
					self.polygon.setMap(null);
					self.mouseTool.close(true);
					self.flagBit = false;
					self.renderFenceOk = false;
				}
				//网格坐标
				if (this.form3.netLocation) {
					let str = this.form3.netLocation;
					let strArr = str.split(';');
					let strArrs = strArr.map(item => {
						return item.split(',')
					})
					this.polygons = [{
						path: strArrs
					}];
					//this.netCenter = strArrs[0];
					this.netMapText = '重新绘制网格';
				} else {
					this.netMapText = '绘制网格';
					this.netCenter = [118.627895, 32.059093];
					this.polygons = [];
				}

				if (self.marker) {
					self.marker.setMap(null);
					self.mouseTool.close(true);
				}
				//中心坐标
				if (this.form3.centerLocation) {
					//console.log(this.form3.centerLocation);
					let location = this.form3.centerLocation;
					let locationArr = location.split(',');
					//console.log(locationArr);
					this.netCenter = [...locationArr];
					this.markers = [{
						position: [locationArr[0], locationArr[1]]
					}];
				} else {
					this.netCenter = [118.627895, 32.059093];
					this.markers = [];
				}
			}
		}
	};
</script>

<style lang="scss" scoped="scoped">
	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;
		border: solid 1px #d7dde4;
		box-shadow: 0 0 3px rgba($color: #d7dde4, $alpha: 0.3);

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: '+';
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: '-';
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;
		}
	}

	.right>div {
		box-sizing: border-box;
		height: calc(100% - 32px);
		overflow-y: auto;
	}

	.form3style {
		padding: 0 20px;

		.el-form-item {
			.spanstyle {
				padding-left: 25px;
				font-size: 20px;
				background: url(../../assets/imgs/edit.png) no-repeat left center;
				background-size: 20px auto;
				color: #5B93C1;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
</style>